<template>
    <article id="job-selected" class="layer-job-selected d-flex row nowrap">
        <div class="close" id="close-job" @click.prevent="showJob();">
            <b-icon icon="x"></b-icon>
        </div>

        <b-row>
            <b-col class="col-4">
                <div>
                    <img :src="job.link_image" alt="" v-if="job.link_image != null">
                    <img src="../assets/images/christin-hume-Hcfwew744z4-unsplash.jpg" alt="" v-else>
                </div>
            </b-col>
            <b-col class="col-1"></b-col>
            <b-col class="col-7">
                <h2>{{ job.title }}</h2>
                <h3>{{ job.project }}</h3>
                <p>Cliente</p>
                    <img :src="job.image_client">
            </b-col>
        </b-row>

    </article>
</template>
<script>
export default {
    name: "ItemJob",
    props: {
        job: {
            link_image: null,
            title: "Lorem ipsum",
            project: "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras feugiat vehicula lectus, ac" +
                "                        consectetur metus. Phasellus semper mi ut velit tristique dignissim. Suspendisse tempus mi ac" +
                "                        venenatis cursus. Nullam accumsan purus arcu, et dapibus felis luctus eget. Curabitur fringilla" +
                "                        nunc id neque tristique</p>" +
                "                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras feugiat vehicula lectus, ac" +
                "                        consectetur metus. Phasellus semper mi ut velit tristique dignissim. Suspendisse tempus mi ac" +
                "                        venenatis cursus. Nullam accumsan purus arcu, et dapibus felis luctus eget. Curabitur fringilla" +
                "                        nunc id neque tristique</p>" +
                "                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras feugiat vehicula lectus, ac" +
                "                        consectetur metus. Phasellus semper mi ut velit tristique dignissim. Suspendisse tempus mi ac" +
                "                        venenatis cursus. Nullam accumsan purus arcu, et dapibus felis luctus eget. Curabitur fringilla" +
                "                        nunc id neque tristique</p>",
            cliente: "Cliente"
        }
    },

    methods: {
        showJob() {
            document.querySelector('html').classList.toggle('no-scroll');
            document.getElementById("job-selected").classList.toggle("actived");
        }
    }
}
</script>


<style scoped lang="scss">
@import "../assets/scss/global";

// layer de job selecionado
.layer-job-selected {
    overflow: auto;
    background-color: $white;
    padding: 40px;
    position: fixed;
    bottom: 0;
    height: 100vh;
    display: flex;
    transform: translateY(100vh);
    transition: all .5s $custom;
    z-index: 12;

    .row {
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: flex-start;

    }

    h2 {
        color: $cor1;
        font-size: 30px;
    }

    h3, p {
        color: $cor2;
    }

    h3 {
        font-size: 26px;
    }

    p {
        font-size: 21px;
        margin-bottom: 15px;
    }

    &.actived {
        transform: translateY(0);
    }
}

////fim layer

ul {
    width: 100%;
    display: flex;
    flex-flow: row wrap;

    li {
        position: relative;
        display: block;
        overflow: hidden;

        picture {
            width: 300px;
            height: 300px;
            position: relative;
            display: block;
            overflow: hidden;
            cursor: pointer;

            img {
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 0;
                transition: all .7s ease;
                filter: sepia(0);
            }
        }

        .caption {
            background-color: rgba($cor2, .7);
            z-index: 1;
            position: absolute;
            bottom: 20px;
            left: 0px;
            right: 0px;
            padding: 5px 15px;
            transition: all .5s ease;
            transform: translateY(150px);
            opacity: 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;

            p {
                color: $cor1;
                font-size: 18px;
                width: 260px;
                padding-right: 10px;
            }

            .b-icon {
                color: $cor1;
                fill: $cor1;

                svg {
                }
            }
        }

        &:hover {
            img {
                transform: scale(1.1);
                filter: sepia(100%);
            }

            .caption {
                transform: translateY(0px);
                opacity: 1;
            }
        }
    }
}

//Números Trabalho
.trabalho-numeros {
    span {
        font-size: 2rem;
        font-weight: 900;
    }

    p {
        color: $cor1;

        &::before {
            content: "";
            width: 7px;
            height: 7px;
            border-radius: 50%;
            position: absolute;
            margin-top: 12px;
            margin-left: -15px;
            background: $cor1;

        }
    }
}

</style>

